exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidate-profile-2-js": () => import("./../../../src/pages/candidate-profile-2.js" /* webpackChunkName: "component---src-pages-candidate-profile-2-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company-profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-applicants-js": () => import("./../../../src/pages/dashboard-applicants.js" /* webpackChunkName: "component---src-pages-dashboard-applicants-js" */),
  "component---src-pages-dashboard-candidates-js": () => import("./../../../src/pages/dashboard-candidates.js" /* webpackChunkName: "component---src-pages-dashboard-candidates-js" */),
  "component---src-pages-dashboard-jobs-js": () => import("./../../../src/pages/dashboard-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-jobs-js" */),
  "component---src-pages-dashboard-main-js": () => import("./../../../src/pages/dashboard-main.js" /* webpackChunkName: "component---src-pages-dashboard-main-js" */),
  "component---src-pages-dashboard-not-allowed-candidates-js": () => import("./../../../src/pages/dashboard-not-allowed-candidates.js" /* webpackChunkName: "component---src-pages-dashboard-not-allowed-candidates-js" */),
  "component---src-pages-dashboard-request-access-js": () => import("./../../../src/pages/dashboard-request-access.js" /* webpackChunkName: "component---src-pages-dashboard-request-access-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard-settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-[id]-js": () => import("./../../../src/pages/job-details/[id].js" /* webpackChunkName: "component---src-pages-job-details-[id]-js" */),
  "component---src-pages-job-details-index-js": () => import("./../../../src/pages/job-details/index.js" /* webpackChunkName: "component---src-pages-job-details-index-js" */),
  "component---src-pages-job-detailss-js": () => import("./../../../src/pages/job-detailss.js" /* webpackChunkName: "component---src-pages-job-detailss-js" */),
  "component---src-pages-landing-2-js": () => import("./../../../src/pages/landing-2.js" /* webpackChunkName: "component---src-pages-landing-2-js" */),
  "component---src-pages-landing-3-js": () => import("./../../../src/pages/landing-3.js" /* webpackChunkName: "component---src-pages-landing-3-js" */),
  "component---src-pages-my-applications-js": () => import("./../../../src/pages/my-applications.js" /* webpackChunkName: "component---src-pages-my-applications-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reset-password-[token]-js": () => import("./../../../src/pages/reset-password/[token].js" /* webpackChunkName: "component---src-pages-reset-password-[token]-js" */),
  "component---src-pages-reset-password-index-js": () => import("./../../../src/pages/reset-password/index.js" /* webpackChunkName: "component---src-pages-reset-password-index-js" */),
  "component---src-pages-search-jobs-js": () => import("./../../../src/pages/search-jobs.js" /* webpackChunkName: "component---src-pages-search-jobs-js" */),
  "component---src-pages-search-list-2-js": () => import("./../../../src/pages/search-list-2.js" /* webpackChunkName: "component---src-pages-search-list-2-js" */),
  "component---src-pages-search-list-js": () => import("./../../../src/pages/search-list.js" /* webpackChunkName: "component---src-pages-search-list-js" */),
  "component---src-pages-verify-email-[token]-js": () => import("./../../../src/pages/verify-email/[token].js" /* webpackChunkName: "component---src-pages-verify-email-[token]-js" */),
  "component---src-pages-verify-email-index-js": () => import("./../../../src/pages/verify-email/index.js" /* webpackChunkName: "component---src-pages-verify-email-index-js" */)
}

